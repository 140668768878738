import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { FiscalPrinter } from '../../models';
import { FiscalPrintersService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';

@Injectable()
export class FiscalPrintersStoreEffects {
  constructor(
    private dataService: FiscalPrintersService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
    private translate: TranslateService,
    private warningModalService: WarningConfirmModalService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ invoiceLayoutId, trashed, page }) =>
        this.dataService.load(invoiceLayoutId, trashed, page).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ itemId }) =>
        this.dataService.loadDetails(itemId).pipe(
          map((response: FiscalPrinter) =>
            fromActions.loadDetailsSuccess({
              item: response,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ itemId, force_delete }) =>
        this.dataService.delete(itemId, force_delete).pipe(
          map((response) => {
            if (!response.meta.confirm_required) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('fiscal_printers'),
                  },
                ),
                type: 'success',
              });

              return fromActions.deleteSuccess({
                itemId,
              });
            }

            this.warningModalService.open({
              message: response.meta.warnings.toString(),
              action: fromActions.deleteRequest({
                itemId,
                force_delete: true,
              }),
            });

            return fromActions.deleteFailure({
              error: null,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request }) =>
        this.dataService.create(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('fiscal_printers'),
              }),
              type: 'success',
            });
            this.router.navigate(['/configuration/invoicing/fiscal-printers']);
            return fromActions.createSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request }) =>
        this.dataService.update(request).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('fiscal_printers'),
              }),
              type: 'success',
            });
            this.router.navigate(['/configuration/invoicing/fiscal-printers']);
            return fromActions.updateSuccess({
              item: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handleErrors(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  setNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNumberRequest),
      switchMap(
        ({
          receiptId,
          number,
          status,
          printer_id,
          clousure_number,
          document_date,
        }) =>
          this.dataService
            .setNumber(
              receiptId,
              number,
              status,
              printer_id,
              clousure_number,
              document_date,
            )
            .pipe(
              map(() => {
                return fromActions.setNumberSuccess();
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.setNumberFailure(error));
              }),
            ),
      ),
    ),
  );
}
