import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { FiscalPrinter } from '../../models';
import { IPagination } from '../../core/models/api/pagination/pagination.model';

export const featureAdapter: EntityAdapter<FiscalPrinter> =
  createEntityAdapter<FiscalPrinter>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<FiscalPrinter> {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
});
