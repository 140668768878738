import { createAction, props } from '@ngrx/store';

import {
  CreateFiscalPrinterRequest,
  FiscalPrinter,
  SetNumberRequest,
  UpdateFiscalPrinterRequest,
} from '../../models';
import { IPagination } from '../../core/models/api/pagination/pagination.model';

export const loadRequest = createAction(
  '[Fiscal Printers] Load Request',
  props<{ invoiceLayoutId: number; trashed: boolean; page: number }>(),
);
export const loadSuccess = createAction(
  '[Fiscal Printers] Load Success',
  props<{ items: FiscalPrinter[]; pagination: IPagination }>(),
);
export const loadFailure = createAction(
  '[Fiscal Printers] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Fiscal Printers] Load Details Request',
  props<{ itemId: number }>(),
);
export const loadDetailsSuccess = createAction(
  '[Fiscal Printers] Load Details Success',
  props<{ item: FiscalPrinter }>(),
);
export const loadDetailsFailure = createAction(
  '[Fiscal Printers] Load Details Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Fiscal Printers] Delete Request',
  props<{ itemId: number; force_delete?: boolean }>(),
);
export const deleteSuccess = createAction(
  '[Fiscal Printers] Delete Success',
  props<{ itemId: number }>(),
);
export const deleteFailure = createAction(
  '[Fiscal Printers] Delete Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Fiscal Printers] Create Request',
  props<{ request: CreateFiscalPrinterRequest }>(),
);
export const createSuccess = createAction(
  '[Fiscal Printers] Create Success',
  props<{ item: FiscalPrinter }>(),
);
export const createFailure = createAction(
  '[Fiscal Printers] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Fiscal Printers] Update Request',
  props<{ request: UpdateFiscalPrinterRequest }>(),
);
export const updateSuccess = createAction(
  '[Fiscal Printers] Update Success',
  props<{ item: FiscalPrinter }>(),
);
export const updateFailure = createAction(
  '[Fiscal Printers] Update Failure',
  props<{ error: any }>(),
);

export const setNumberRequest = createAction(
  '[Fiscal Printers] Set Number Request',
  props<SetNumberRequest>(),
);
export const setNumberSuccess = createAction(
  '[Fiscal Printers] Set Number Success',
);
export const setNumberFailure = createAction(
  '[Fiscal Printers] Set Number Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Fiscal Printers] Reset State');
